<template>
    <div class="container container-tight">
        <div class="block"></div>

        <div class="container">
            <div class="ts-measure ts-measure-center">
                <h1 class="page-title">{{ $t("pages.contact.title") }}</h1>
            </div>
        </div>

        <div class="block" v-if="!success">
            <LoaderWrapper :loading="loading" radius="80px">
                <div class="panel">
                    <div class="panel-header">
                        <h3 class="panel-title">{{ $t("label.contact_form") }}</h3>
                    </div>
                    <div class="panel-body">
                        <form @submit.prevent="send">
                            <BaseInput
                                :label="$t('label.full_name')"
                                v-model="contact.name"
                                name="name"
                                type="text"
                                required
                            ></BaseInput>
                            <div :class="{ 'form-error': this.error.contact }">
                                <BaseInput
                                    :label="$t('label.email')"
                                    v-model="contact.email"
                                    type="email"
                                    name="email"
                                    required
                                ></BaseInput>
                                <p class="helper-block error" v-if="this.error.contact">
                                    {{ $t("error.invalid_email") }}
                                </p>
                            </div>
                            <BaseInput
                                :label="$t('label.subject')"
                                v-model="contact.subject"
                                type="text"
                                name="subject"
                                required
                            ></BaseInput>
                            <BaseInput
                                class="mb-0"
                                :label="$t('label.message')"
                                v-model="contact.message"
                                type="textarea"
                                name="message"
                                required
                            ></BaseInput>
                            <p class="mt-0 ts-quiet">{{ $t("pages.contact.message_note") }}</p>
                            <BaseInput
                                :label="$t('label.device')"
                                v-model="contact.device"
                                type="text"
                                name="device"
                            ></BaseInput>
                            <div :class="{ 'form-error': this.error.submit }">
                                <p class="helper-block error" v-if="this.error.submit">
                                    {{ $t("error.contact_error") }}
                                </p>
                            </div>

                            <div class="row">
                                <button type="submit" class="button row-push button-primary">
                                    {{ $t("button.send") }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </LoaderWrapper>
        </div>

        <div class="block" v-if="success">
            <div class="panel">
                <div class="panel-header">
                    <h3 class="panel-title">{{ $t("label.contact_form") }}</h3>
                </div>
                <div class="panel-body">
                    <h3>{{ $t("pages.contact.thanks.title") }}</h3>
                    <p class="ts-quiet">
                        {{ $t("pages.contact.thanks.text") }}
                    </p>
                    <p class="ts-quiet" v-html="$t('pages.contact.thanks.last')"></p>

                    <p class="ts-quiet ts-small mb-3">
                        {{ $t("pages.contact.thanks.note") }}
                    </p>

                    <i18n-link class="button row-push button-primary" :to="{ name: 'home' }">{{
                        $t("button.back_to_home")
                    }}</i18n-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseInput from "@/components/base/BaseInput"
import LoaderWrapper from "@/components/LoaderWrapper"
import { load } from "recaptcha-v3"

export default {
    components: {
        BaseInput,
        LoaderWrapper,
    },
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.contact.title"),
            description: this.$i18n.t("meta.contact.description"),
        })
    },
    async mounted() {
        this.recaptcha = await load(process.env.VUE_APP_RECAPTCHA_PUBLIC, {
            useRecaptchaNet: true,
        })

        // Receive user information from App
        this.contact.name = this.$route.query.name ? this.$route.query.name : ""
        this.contact.email = this.$route.query.email ? this.$route.query.email : ""
    },
    methods: {
        async send() {
            this.error.submit = false
            if (!this.validateEmail()) {
                this.error.contact = true
                return
            } else {
                this.error.contact = false
            }

            this.loading = true

            try {
                const token = await this.recaptcha.execute("contact")
                const response = await fetch(`${this.$apiURL}/public/contact`, {
                    method: "POST",
                    body: JSON.stringify({ ...this.contact, recaptcha: token }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                })

                const result = await response.json()

                if (result.message === "Success") {
                    this.loading = false
                    this.success = true
                    this.contact = {
                        subject: "",
                        message: "",
                        email: "",
                        name: "",
                        device: "",
                        locale: this.$i18n.locale,
                    }
                } else {
                    this.success = false
                    this.loading = false
                    this.error.submit = true
                }
            } catch (e) {
                console.log(e.toString())
            }
        },
        validateEmail() {
            const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
            this.errorEmail =
                !expression.test(String(this.contact.email).toLowerCase()) ||
                this.contact.email.slice(-4) === ".con"
            return !this.errorEmail
        },
    },
    data() {
        return {
            recaptcha: null,
            contact: {
                subject: "",
                message: "",
                email: "",
                name: "",
                device: "",
                locale: this.$i18n.locale,
            },
            error: {
                contact: false,
                submit: false,
            },
            success: false,
            loading: false,
        }
    },
}
</script>
